@import "../../../../_metronic/_assets/sass/init";

.login.login-1 {
  &.login-signin-on {
    .login-signin {
      display: block;
      margin-left: 10rem;

      &.container-reset-pass {
        .container-title {
          margin-bottom: 3rem !important;
        }
        .container-title-step {
          width: 75%;
          margin-bottom: 1rem;
          .reset-title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
          }
          .reset-subtitle {
            font-size: 14px;
            margin-bottom: 0.25rem;
          }
        }
        .form {
          .container-reset {
            margin-top: 3rem;
          }
        }
      }
      .container-title {
        width: 75%;
        .name-company {
          font-weight: 500;
          color: #215a6d;
        }
      }
    }
  }
}
