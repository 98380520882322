.modal_level {
  .modal-content {
    width: 420px;
    .modal-body {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      padding-top: 0;
      .label-text {
        color: #939393;
        margin-bottom: 0.4rem;

        .text-required {
          color: #f95d5d;
        }
      }
      .select-level {
        align-self: center;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
        border-radius: 0.5rem;
        padding: 0.6rem 3rem 0.6rem 1rem;
        background-image: url("/media/svg/icons/Design/Arrow_down.svg");
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: 50%;
      }
    }
  }
}
