@import "../../../../_metronic/_assets/sass/init";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.login.login-1 {
  background: #f3f5f9 !important;

  &.login-signin-on {
    .login-signin {
      display: block;
      margin-left: 10rem;

      &.container-reset-pass {
        .container-title {
          margin-bottom: 3rem !important;
        }
        .container-title-step {
          width: 75%;
          margin-bottom: 1rem;
          .reset-title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
          }
          .reset-subtitle {
            font-size: 14px;
            margin-bottom: 0.25rem;
          }
        }
        .form {
          .container-reset {
            margin-top: 3rem;
          }
        }
      }
      .container-title {
        width: 75%;
        .name-company {
          font-weight: 500;
          color: #215a6d;
        }
      }
      .form {
        .form-group {
          margin-bottom: 0.5rem;
        }
        .error-message-container {
          height: 0.8rem;
          margin-bottom: 0.35rem;

          .fv-help-block {
            color: $danger;
            font-size: 1rem;
            font-weight: 400;
          }
        }
        .container-footer {
          width: 75%;
          .forgot_pass {
            cursor: pointer;
            p {
              color: #5383ff;
            }
          }
        }
        .container-input {
          position: relative;
          .text-input {
            width: 75%;
            .MuiInputBase-root {
              .MuiOutlinedInput-input {
                padding: 1.5rem 3.25rem;
              }
            }
          }
          .icon-input {
            display: inline-block;
            position: absolute;
            bottom: 25%;
            left: 0.75rem;
            width: 22px;
            height: 22px;
            z-index: 99;
          }
        }
        #kt_login_signin_submit {
          border-radius: 12;
          border: none;
          background-color: #03989e;
          width: 75%;
          span {
            font-size: 18px;
          }
        }
      }
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-signup-on {
    .login-signup {
      display: block;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-forgot-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: block;
    }
  }
}
.login.login-1 {
  .login-aside {
    background-color: #ffffff;
    .wrapper-logo {
      height: 15vh;
    }
    h2 {
      color: #2f3044;
    }
    .footer-background {
      width: 100%;
    }
  }
  .btn-previous {
    background-color: #e1f0ff !important;
    span {
      color: #3699fe;
    }
  }
}
// Desktop view
@include media-breakpoint-up(lg) {
  .login.login-1 {
    .login-aside {
      width: 100%;
      max-width: 750px;
    }

    .login-form {
      width: 100%;
      max-width: 600px;
    }
  }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
  .login.login-1 {
    .login-aside {
      width: 100%;
      max-width: 400px;
    }
  }
}

// Tablet mode
@include media-breakpoint-down(md) {
  .login.login-1 {
    .login-form {
      width: 100%;
      max-width: 350px;
    }
  }
}

// Mobile mode
@include media-breakpoint-down(xs) {
  .login.login-1 {
    .login-form {
      width: 100%;
      max-width: 100%;
    }
  }
}
