.wrapper-card {
  padding: 1.25rem;
  border-radius: 0.2rem;
  .header-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    .title-header {
      font-size: 20px;
      font-weight: 700;
    }
    .btn-actions-user {
      padding: 0.25rem 1rem;
    }
  }
  .content-card {
    margin-top: 1rem;
    .info-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 0.75rem;
      width: 100%;
      .info-text {
        color: #8c8c8c;
        font-size: 15px;
        font-weight: 600;
        max-width: 20%;
        width: 200px;
      }
      .detail-info {
        color: #262626;
        font-weight: 500;
        margin-left: 1.5rem;
        text-align: left;
      }
    }
  }
}
