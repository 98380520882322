.table-manage-appointment {
  .body-manage-appointment {
    .header-table {
      width: 100% !important;
      margin-bottom: 2rem;
      .wrapper-input-search {
        width: 65%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .input-container {
          width: 90%;
          input {
            width: 100%;
            padding: 0.75rem 2rem 0.75rem 1rem;
            border-radius: 0.5rem;
            border: 1px solid #ebedf3;
            &:focus {
              outline: none !important;
              border: 1px solid #3e3e3f;
            }
          }
        }
        .wrapper-search {
          margin-left: 1rem;
          .btn-search {
            padding: 0.75rem 3rem;
            border-radius: 0.5rem;
          }
        }
      }
      .wrapper-actions-table {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn-filter {
          padding: 0.9rem 2.5rem;
          border: none;
          outline: none;
          background-color: white;
          border: 1px solid #e0e0e0;
          &.btn-active {
            border: 1px solid #1890ff;
            background-color: #e6f7ff;
            span {
              color: #1890ff;
            }
          }
        }
      }
    }

    table tr td {
      .partner-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .info-partner {
          img {
            width: 60px;
            height: 60px;
            border-radius: 0.75rem;
          }

          .name-user {
            width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .name-user {
          color: #1890ff;
          font-size: 14px;
          margin-top: 0.5rem;
        }
      }
      .appoint-info-wrapper {
        display: flex;
        .title-container {
          flex: 3;
          margin-bottom: 0;
          .title-info {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0.25rem;
          }
        }
        .detail-container {
          flex: 7;
          .detail-info {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0.25rem;
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.username {
              color: #1890ff !important;
            }
          }
        }
      }
    }
    table thead tr th:nth-child(2) {
      text-align: center;
      // margin-left: 2rem;
      // display: inline-block;
    }
  }
}

.data-table {
  background: #ffffff;
  .wrapper-input-search {
    max-width: 400px;
  }
  .text-nodata {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .divide {
    height: 1px;
    background: #ebedf3;
    margin-top: 1rem;
  }

  table {
    border: none;
    background-color: #ffffff;

    tbody {
      td {
        cursor: pointer;
      }
    }

    td,
    th {
      border: none;
      border-top: 1px solid #ebedf3;
      padding: 1rem 0.75rem;
      vertical-align: middle;
    }

    thead th {
      font-size: 1.25rem;
    }

    thead .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    thead .dropup .caret,
    thead .navbar-fixed-bottom .dropdown .caret {
      border-top: 0;
      border-bottom: 4px dashed;
      border-bottom: 4px solid;
      content: "";
    }

    .order,
    .react-bootstrap-table-sort-order {
      margin-left: 10px;
    }
  }

  &-author {
    font-size: 1.25rem;
    word-break: break-word;
    white-space: break-spaces;
  }

  &-company {
    font-size: 1rem;
    color: #b5b5c3;
    word-break: break-word;
  }

  .input-wrapper {
    width: 20rem;
  }

  .icon-search {
    position: absolute;
    right: 1rem;
    top: 0.75rem;
  }

  .tag-status {
    height: 2.5rem;
    width: 9rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .count-task {
    height: 2.5rem;
    min-width: 10.5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.25rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  .react-bootstrap-table-pagination-list {
    display: flex;
    justify-content: flex-end;
  }

  .data-table-message {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
  }
}

.data-table {
  background: #ffffff;
  .wrapper-input-search {
    max-width: 400px;
  }
  .text-nodata {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .divide {
    height: 1px;
    background: #ebedf3;
    margin-top: 1rem;
  }

  table {
    border: none;
    background-color: #ffffff;

    tbody {
      td {
        cursor: pointer;
      }
    }

    td,
    th {
      border: none;
      border-top: 1px solid #ebedf3;
      padding: 1rem 0.75rem;
      vertical-align: middle;
    }

    thead th {
      font-size: 1.25rem;
    }

    thead .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    thead .dropup .caret,
    thead .navbar-fixed-bottom .dropdown .caret {
      border-top: 0;
      border-bottom: 4px dashed;
      border-bottom: 4px solid;
      content: "";
    }

    .order,
    .react-bootstrap-table-sort-order {
      margin-left: 10px;
    }
  }

  &-author {
    font-size: 1.25rem;
    word-break: break-word;
    white-space: break-spaces;
  }

  &-company {
    font-size: 1rem;
    color: #b5b5c3;
    word-break: break-word;
  }

  .input-wrapper {
    width: 20rem;
  }

  .icon-search {
    position: absolute;
    right: 1rem;
    top: 0.75rem;
  }

  .tag-status {
    height: 2.5rem;
    width: 9rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .count-task {
    height: 2.5rem;
    min-width: 10.5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.25rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  .react-bootstrap-table-pagination-list {
    display: flex;
    justify-content: flex-end;
  }

  .data-table-message {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
  }
}
