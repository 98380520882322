.form-account-info {
  padding: 1rem 2rem;
  margin-left: 1rem;
  .header-form {
    margin-bottom: 2rem;
    font-size: 22px;
    font-weight: 700;
  }
  .body-form {
    .form-group {
      margin-bottom: 1rem;
      .input-form-group {
        width: 100%;
        border-radius: 0.5rem;
        padding: 1rem 0.5rem;
        border: 1px solid #e0e0e0;
        &.input-disable {
          background-color: #e0e0e0;
        }
        &.error-input {
          border: 1px solid #ff0000;
        }
      }
      input[type="text"] {
        &:focus {
          border: 1px solid #1890ff;
        }
      }
      .label-input-form {
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 0.5rem;
        .required-field {
          color: #d63031;
          font-size: 15px;
        }
      }
      .message-err-input {
        margin-top: 0.5rem;
        height: 0.9rem;
        .title-invalid {
          color: #ff0000;
        }

        span,
        p {
          color: #9a9ea1;
        }
      }
    }
  }
  .footer-form {
    margin-top: 2rem;
    .btn-save {
      padding: 0.75rem 2rem !important;
      border-radius: 0.5rem !important;
      &.btn-disable {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }
}
