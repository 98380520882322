.card-appointment-info {
  padding: 0.75rem;
  background-color: white;

  .info-container {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .container-partner {
      .partner-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .reverse-icon {
          margin-top: -1.5rem;
        }

        .info-partner {
          &:first-child {
            margin-right: 0.5rem;
          }
          &:last-child {
            margin-left: 0.5rem;
          }
          img {
            width: 100px;
            height: 100px;
            border-radius: 0.75rem;
          }
          .name-user {
            width: 100px;
            color: #1890ff;
            font-size: 14px;
            margin-top: 0.5rem;
            text-align: center;
            cursor: default;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
      .username {
        font-weight: 600;
        font-size: 16px;
        margin-top: 1rem;
      }
    }
  }

  .card-footer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .box-info {
      border: 1px solid #f0f0f0;
      padding: 2rem 1rem;
      margin-bottom: 0.25rem;
      width: 48%;
      min-width: 200px;
      text-align: center;
      &.has-select {
        cursor: pointer;
      }
      .subtitle-box {
        font-size: 18px;
        color: #838282;
      }
      span {
        color: black;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
