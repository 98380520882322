.card-user-info {
  position: relative;
  padding: 0.75rem;
  height: 380px;
  background-color: white;

  .box-color {
    height: 40%;
    background-color: #f0f0f0;
    position: relative;
    .info-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      margin-bottom: 2rem;
      left: 50%;
      top: 100%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      &.no-click {
        pointer-events: none;
        cursor: default;
      }
      .container-avatar {
        .avatar-user {
          width: 120px;
          height: 120px;
          border-radius: 50%;
        }
        .overlay-avatar {
          position: absolute;
          top: 39%;
          left: 50%;
          width: 120px;
          height: 120px;
          border-radius: 50%;
          background-color: #00000048;
          z-index: 100;
          display: none;
          transition: all 0.1ms;
          transform: translate(-50%, -50%);
        }
        &:hover {
          .overlay-avatar {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .username {
        font-weight: 600;
        font-size: 16px;
        margin-top: 1rem;
      }
    }
  }

  .card-footer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .box-info {
      border: 1px solid #f0f0f0;
      padding: 2rem 1rem;
      margin-top: 8.5rem;
      width: 48%;
      min-width: 200px;
      text-align: center;
      &.has-select {
        cursor: pointer;
      }
      .subtitle-box {
        font-size: 18px;
        color: #838282;
      }
      span {
        color: black;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
