.table-manage-post {
  .body-manage-post {
    .header-table {
      width: 100% !important;
      margin-bottom: 2rem;
      .wrapper-input-search {
        width: 785%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .input-container {
          width: 90%;
          input {
            width: 100%;
            padding: 0.75rem 2rem 0.75rem 1rem;
            border-radius: 0.75rem;
            border: 1px solid #ebedf3;
            &:focus {
              outline: none !important;
              border: 1px solid #3e3e3f;
            }
          }
        }
        .wrapper-search {
          margin-left: 1rem;
          .btn-search {
            padding: 0.75rem 3rem;
            border-radius: 0.5rem;
          }
        }
      }
      .wrapper-actions-table {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn-export {
          background-color: #4ecb73;
          padding: 0.75rem 3rem;
          border-radius: 0.5rem;
          color: white;
        }
      }
    }

    table tr td {
      .image-avatar {
        img {
          width: 38px;
          height: 38px;
          border-radius: 0.75rem;
        }
      }
      .preferred-name,
      .full-name {
        width: 80%;
        font-weight: 500;
        word-break: break-all;
      }
    }
  }
}

.data-table {
  background: #ffffff;
  .wrapper-input-search {
    max-width: 400px;
  }
  .text-nodata {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .divide {
    height: 1px;
    background: #ebedf3;
    margin-top: 1rem;
  }

  table {
    border: none;
    background-color: #ffffff;

    tbody {
      td {
        cursor: pointer;
      }
    }

    td,
    th {
      border: none;
      border-top: 1px solid #ebedf3;
      padding: 1rem 0.75rem;
      vertical-align: middle;
    }

    thead th {
      font-size: 1.25rem;
      width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    thead .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    thead .dropup .caret,
    thead .navbar-fixed-bottom .dropdown .caret {
      border-top: 0;
      border-bottom: 4px dashed;
      border-bottom: 4px solid;
      content: "";
    }

    .order,
    .react-bootstrap-table-sort-order {
      margin-left: 10px;
    }
  }

  &-author {
    font-size: 1.25rem;
    word-break: break-word;
    white-space: break-spaces;
  }

  &-company {
    font-size: 1rem;
    color: #b5b5c3;
    word-break: break-word;
  }

  .input-wrapper {
    width: 20rem;
  }

  .icon-search {
    position: absolute;
    right: 1rem;
    top: 0.75rem;
  }

  .tag-status {
    height: 2.5rem;
    width: 9rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .count-task {
    height: 2.5rem;
    min-width: 10.5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.25rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  .react-bootstrap-table-pagination-list {
    display: flex;
    justify-content: flex-end;
  }

  .data-table-message {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
  }
}
