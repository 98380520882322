.wrapper-dialog {
  padding: 1.25rem;
  border-radius: 0.2rem;
  .header-dialog {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    .title-header {
      font-size: 20px;
      font-weight: 700;
    }
    .btn-actions-user {
      padding: 0.25rem 1rem;
    }
    .wrapper-actions-dialog {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-action {
        padding: 0.75rem 1.5rem;
        border-radius: 0.5rem;
        color: white;
        &.btn-accept {
          background-color: #4ecb73;
        }
        &.btn-refuse {
          margin-left: 0.75rem;
          background-color: #f95d5d;
        }
      }
    }
  }
  .content-dialog {
    width: 100%;
    margin-top: 1rem;
    table {
      tbody {
        tr {
          border: none;
          td {
            padding: 0.5rem;
            border: none;
          }
          .info-text {
            width: 30%;
            color: #8c8c8c;
            font-size: 15px;
            font-weight: 600;
            word-wrap: break-word;
          }
          .detail-info {
            color: #262626;
            font-weight: 500;
            margin-left: 2rem;
            text-align: left;
            ul {
              margin-left: -1.5rem;
            }
          }
        }
      }
    }
    .info-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.75rem;
    }
    .container-attachment {
      padding-top: 1rem;
      border-top: 1px solid #f0f0f0;
      .title-attach {
        font-size: 16px;
        color: #262626;
        margin-bottom: 1rem;
        margin-left: 0.5rem;
        font-weight: 600;
      }
      table {
        width: 100%;
        tbody {
          width: 100%;
          tr {
            border: none;
            td {
              padding: 0.5rem;
              border: none;
              text-align: left;
              vertical-align: top;
            }
            .info-text {
              width: 30%;
              color: #8c8c8c;
              font-size: 15px;
              font-weight: 600;
              word-wrap: break-word;
            }
            .detail-info {
              color: #262626;
              font-weight: 500;
              margin-left: 2rem;
              text-align: left;

              .item-attach {
                margin: 0;
                margin-bottom: 1rem;
                a {
                  width: 100%;
                  max-width: 600px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}
