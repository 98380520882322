.statistical-container {
  border-radius: 0.25rem;
  .sub-header {
    span {
      font-weight: 700;
      font-size: 18px;
    }
  }
  .statistical-content {
    .list-card-container {
      margin-bottom: 2rem;
      .title-list-card {
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
  .line-break {
    border-bottom: 1px solid #e0e0e0;
  }
  .chart-container {
    .filter-chart {
      .choose-time {
        .title-choose {
          margin-bottom: 0.25rem;
          font-weight: 16px;
        }
        .container-datepicker {
          position: relative;
          .icon-calendar {
            position: absolute;
            top: 0.3rem;
            z-index: 99;
            left: 0.75rem;
          }
        }

        .react-datepicker-wrapper {
          width: 220px !important;
        }
        .react-datepicker-wrapper .react-datepicker__input-container {
          width: 100%;
        }
        .react-datepicker-wrapper .react-datepicker__input-container input {
          width: 100%;
          padding-top: 0.6rem !important;
          padding-bottom: 0.6rem !important;
          padding-left: 2.75rem !important;
          border-radius: 0.5rem;
          border: 1px solid black;
        }
      }
      .select-wrapper {
        margin-left: 1.5rem;
        .title-select {
          margin-bottom: 0.25rem;
          font-weight: 16px;
        }
        .select-filter {
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: "";
          border-radius: 0.5rem;
          padding: 0.6rem 3rem 0.6rem 1rem;
          background-image: url("/media/svg/icons/Design/Arrow_down.svg");
          background-repeat: no-repeat;
          background-position-x: 90%;
          background-position-y: 50%;
        }
      }
    }
    .btn-filter {
      margin-left: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
