.card-appointment-history {
  padding: 0.5rem 1rem;
  .header-card {
    margin-bottom: 1rem;
    .title-header {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .content-card {
    .appointment-item {
      cursor: pointer;
      width: 97%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 2rem;
      padding-bottom: 1.25rem;
      margin-left: 1rem;
      border-bottom: 1px solid #e0e0e0;

      &:last-child {
        margin-bottom: 1rem;
      }

      .avatar-pharmacist {
        width: 60px !important;
        height: 60px !important;
        display: inline-block;
        border-radius: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .field-text {
        margin-bottom: 0.25rem;
        color: #262626;
        font-weight: 14px;
        &.pharmacist {
          color: #1890ff;
        }
      }
      .list-title-field {
        margin-left: 1rem;
        flex: 2;
      }
      .list-description {
        flex: 7;
        align-self: flex-start;
      }
      // .action-item {
      //   flex: 4;
      //   display: flex;
      //   justify-content: flex-end;
      //   align-items: flex-start;
      //   .btn-more-action {
      //     padding: 0 0.75rem;
      //     border: 1px solid #e0e0e0;
      //     margin-top: -1rem;
      //   }
      // }
    }
  }
}
