.modal_reject_pharmacist {
  .modal-content {
    width: 500px;
    .modal-body {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      padding-top: 0;
      .body_reject {
        .textarea_reject {
          width: 100% !important;
          &.input-error {
            border: 1px solid #f64e60 !important;
          }
        }
      }

      .fv-plugins-message-container {
        margin-top: 0.5rem;
        height: 0.9rem;

        .fv-help-block {
          color: #f64e60;
          font-size: 0.9rem;
          font-weight: 400;
        }
      }
    }
  }
}
