.card-container {
  padding: 1rem 1rem 0.5rem 1.25rem;
  box-shadow: 1px 1px 4px 0 #00000042;
  border-radius: 0.25rem;
  max-width: 270px;
  margin-right: 3.5rem;
  width: 23%;

  .header-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    .title-header {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 1rem;
    }
    .container-icon {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(149, 198, 62, 1) 0%,
        rgba(3, 152, 158, 0.7) 100%
      );
    }
  }
  .content-card {
    height: 70px;
    .content-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      p {
        max-width: "85%";
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }
  .footer-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-text {
      margin-bottom: 0;
      text-align: left;
      align-self: flex-end;
    }
  }
}
